import { ComponentChildren, h } from 'preact';
import cx from 'classnames';
import './Alert.scss';

const ALERT_TYPES = ['error', 'warning', 'success'];

interface AlertProps {
    children: ComponentChildren;
    classNames?: string[];
    type?: typeof ALERT_TYPES[number];
}

export default function Alert({ children, classNames = [], type = 'error' }: AlertProps) {
    return <div className={cx('adyen-checkout__alert-message', `adyen-checkout__alert-message--${type}`, classNames)}>{children}</div>;
}
